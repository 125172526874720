import moment from 'moment-timezone';

const titleBuilder = (extendedProps, defaultTitle) => {
  const {
    dateType,
    estimatedDailyHours,
    estimatedStartTime,
    estimatedEndTime
  } = extendedProps;
  const formattedEstimatedStartDate = moment(
    estimatedStartTime,
    'HH:mm'
  ).format('h:mma');
  const formattedEstimatedEndDate = moment(estimatedEndTime, 'HH:mm').format(
    'h:mma'
  );
  const actionText =
    defaultTitle === 'Confirmed' ? 'CLICK TO VIEW' : 'CLICK TO EDIT';

  switch (dateType) {
    case 'DEFAULT_RECURRING':
      return `<div><div>Available all day</div><div class="editable">CLICK TO EDIT</div></div>`;
    case 'RECURRING_HOURS':
      return `<div><div>Available for ${estimatedDailyHours} hours daily</div><div class="editable">CLICK TO EDIT</div></div>`;
    case 'RECURRING_SPECIFIC_HOURS':
      return `<div><div>Available ${formattedEstimatedStartDate} - ${formattedEstimatedEndDate} daily</div><div class="editable">CLICK TO EDIT</div></div>`;
    default:
      return `<div><div>${defaultTitle}</div><div class="editable">${actionText}</div></div>`;
  }
};

export default titleBuilder;
