import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import '@fullcalendar/react/dist/vdom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';
import tippy from 'tippy.js';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';

import {
  addLocalAvailability,
  updateLocalAvailability,
  deleteLocalAvailability,
  createOrUpdateAvailabilities,
  getAvailabilities,
  logSuccessfulCreateOrUpdate,
  getBookings
} from 'store/availabilities/availabilities.actions.js';
import { openBookingSidebar } from 'store/v1/ui/ui.actions';
import AVAILABILITIES_ACTIONS from 'store/availabilities/availabilities.constants.js';
import { openModal, displayAlert } from 'store/v1/ui/ui.actions.js';
import { convertDateToDateTime } from 'v1/components/feature/Calendars/ResourceCalendarComponents/BigCalendar/BigCalendar';
import { decodeAndReadToken } from 'lib/auth/tokenOperations';
import { checkOverlapping } from 'v1/helpers/misc';
import titleBuilder from './TippyTemplates';
import {
  DURATION_TYPES,
  DURATION_DEFAULTS
} from 'v1/helpers/byModel/EventHelper';
import useEvent from 'v1/helpers/hooks/useEvent';

import CalendarPens from './CalendarPens/CalendarPens';
import { RESOURCE_PORTAL } from 'features/ResourcePortal/ResourcePortalRoute';
import CalendarInputPopover from './CalendarInputPopover/CalendarInputPopover';
import CalendarDeletePopover from './CalendarDeletePopover/CalendarDeletePopover';
import { Grid, GridCell, PressStud } from 'v1/components/shared';

import styles from './CalendarRoute.module.scss';
import 'styles/v1/FullCalendar.scss';
import './ResourcePortalFullCalendar.scss';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './atellio-tippy.css';

const createLocalEvent = (status, event) => ({
  availabilityId: v4(),
  statusId: status.id,
  status: status.statusType,
  backgroundColor: status.colour,
  borderColor: status.colour,
  startDate: event.startDate,
  endDate: event.endDate,
  dateType: DURATION_TYPES.RECURRING_DEFAULT
});

// stolen from EventHoursInput, needs refactoring
const getHoursLabel = availability => {
  const { dateType, estimatedStartTime, estimatedEndTime } = availability;
  let title = '';

  switch (dateType) {
    case DURATION_TYPES.RECURRING_DEFAULT:
      title = 'All day';
      break;
    case DURATION_TYPES.RECURRING_HOURS:
      title = getRecurringHoursLabel(availability);
      break;
    case DURATION_TYPES.RECURRING_SPECIFIC_HOURS: {
      if (estimatedStartTime && estimatedEndTime) {
        title =
          moment(estimatedStartTime, 'HH').format('ha') +
          ' - ' +
          moment(estimatedEndTime, 'HH').format('ha') +
          ' daily';
        break;
      } else {
        title = 'All day';
        break;
      }
    }
    default:
      title = 'All day';
      break;
  }
  return title;
};

const getRecurringHoursLabel = availability => {
  const { estimatedDailyHours, estimatedDailyMinutes } = availability;
  const mins = Number(estimatedDailyMinutes)
    ? JSON.stringify(estimatedDailyMinutes)
    : '';
  return estimatedDailyHours
    ? estimatedDailyHours + 'h' + mins + ' / day'
    : 'All day';
};

// This creates a struct that can be displayed in the calendar, based on an availability and it's corresponding status config
const createCalendarEventFromAvailability = (availability, status = {}) => {
  let title = '';
  switch (availability.status) {
    case 'AVAILABLE':
      title = getHoursLabel(availability);
      break;
    case 'UNAVAILABLE':
      title = 'Unavailable';
      break;
    case 'HOLD':
      title = `${status.name || 'Hold'} - ${getHoursLabel(availability)}`;
      break;
    default:
      title = 'Unknown';
  }

  // end date manipulation
  const endDateString = availability.endDate.split('T')[0]; // only give moment the year-month-day part of the UTC time stamp
  const endDate = moment(endDateString).add(1, 'days').format('YYYY-MM-DD'); // Offset +1 day to accomodate calendar rendering in exclusive of date

  return {
    id: availability.availabilityId,
    title: title,
    backgroundColor: status.colour,
    borderColor: status.colour,
    start: availability.startDate,
    end: endDate,
    extendedProps: {
      status: availability.status,
      statusId: availability.statusId,
      eventId: availability.eventId,
      dateType: availability.dateType,
      estimatedDailyHours: availability.estimatedDailyHours,
      estimatedDailyMinutes: availability.estimatedDailyMinutes,
      estimatedStartTime: availability.estimatedStartTime,
      estimatedEndTime: availability.estimatedEndTime
    },
    allDay: true
  };
};

const convertAvailabilitiesToEvents = (availabilities, statuses) => {
  return availabilities.map(availability => {
    const availabilityStatusId = availability.statusId;
    const availabilityStatus = statuses.find(status => {
      return status.id === availabilityStatusId;
    });
    return createCalendarEventFromAvailability(
      availability,
      availabilityStatus
    );
  });
};

const createCalendarEventFromBookingEvent = (
  bookingEvent,
  status,
  bookingId
) => {
  // end date manipulation
  const endDateString = bookingEvent.endDate.split('T')[0]; // only give moment the year-month-day part of the UTC time stamp
  const endDate = moment(endDateString).add(1, 'days').format('YYYY-MM-DD'); // Offset +1 day to accomodate calendar rendering in exclusive of date
  const endDateOrDefault = endDate ? endDate : bookingEvent.startDate;

  return {
    bookingId,
    id: bookingEvent.eventId,
    title: status.name,
    backgroundColor: status.colour,
    borderColor: status.colour,
    start: bookingEvent.startDate,
    end: endDateOrDefault,
    extendedProps: {
      eventType: bookingEvent.eventType
    },
    allDay: true
  };
};

const convertBookingEventsToEvents = bookings => {
  if (bookings.length === 0) return [];

  return bookings
    .map(({ events, status, bookingId }) =>
      events
        .filter(
          event =>
            (event.startDate !== undefined || event.startDate !== null) &&
            (event.endDate !== undefined || event.endDate != null)
        )
        .map(event =>
          createCalendarEventFromBookingEvent(event, status, bookingId)
        )
    )
    .flat();
};

const CalendarRoute = () => {
  const dispatch = useDispatch();
  const { availabilityStatuses } = useSelector(state => state.public_settings);
  const {
    request,
    local,
    availabilities,
    deleted,
    createSuccess,
    creating,
    bookings
  } = useSelector(state => state.availabilities);

  const [activeStatus, setActiveStatus] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const {
    companyName,
    companyUserFirstName,
    resourceFullName,
    startTimestampUTC,
    endTimestampUTC
  } = request;
  const startDate = moment(startTimestampUTC).format('Do MMMM');
  const endDate = moment(endTimestampUTC).format('Do MMMM');

  const cookies = new Cookies();

  useEffect(() => {
    const defaultAvailability =
      availabilityStatuses.find(status => status.name === 'Available') ||
      (isArray(availabilityStatuses) && availabilityStatuses.length > 0
        ? availabilityStatuses[0]
        : null);
    setActiveStatus(defaultAvailability);
  }, [availabilityStatuses]);

  useEffect(() => {
    const tokenFromCookie = cookies.get(RESOURCE_PORTAL);
    const { bearerToken, token } = decodeAndReadToken(tokenFromCookie);
    const { teamSchema, resourceId, availabilityRequestId } = token;
    dispatch(
      getAvailabilities(
        teamSchema,
        resourceId,
        startTimestampUTC,
        endTimestampUTC,
        bearerToken
      )
    );

    dispatch(
      getBookings(
        teamSchema,
        resourceId,
        startTimestampUTC,
        endTimestampUTC,
        bearerToken
      )
    );

    if (
      createSuccess === AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES_SUCCESS
    ) {
      dispatch(
        logSuccessfulCreateOrUpdate(
          teamSchema,
          availabilityRequestId,
          bearerToken
        )
      );

      dispatch(
        openModal('SuccessFeedbackModal', {
          title: 'Availabilities Sent',
          description: `${companyUserFirstName} at ${companyName} will be notified on your upcoming availability`,
          icon: '/images/icon_colour_send.svg',
          size: 'XS'
        })
      );
    }
  }, [request, createSuccess]);

  useEffect(() => {
    const isDirty =
      local.length > 0 ||
      availabilities.find(availability => availability.dirty === true) ||
      deleted.length > 0;
    setIsDirty(isDirty);
  }, [local, availabilities, deleted]);

  useEvent([AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES], {
    onFailure: () => dispatch(displayAlert('error', 'An error occurred'))
  });

  const displayableAvailabilities = convertAvailabilitiesToEvents(
    availabilities.filter(
      availability =>
        ['AVAILABLE', 'UNAVAILABLE', 'HOLD'].indexOf(availability.status) !== -1
    ),
    availabilityStatuses
  );

  const displayableLocalAvailabilities = convertAvailabilitiesToEvents(
    local,
    availabilityStatuses
  );

  const displayableBookings = convertBookingEventsToEvents(
    bookings.filter(booking => {
      return booking.status.statusType !== 'HOLD';
    })
  );

  // This calendar shows a combination of system stored availabilties, bookings and locally created ones
  const allEvents = [
    ...displayableAvailabilities,
    ...displayableLocalAvailabilities,
    ...displayableBookings
  ];

  const setActivatedStatus = status => {
    setActiveStatus(prev => (prev === status ? null : status));
  };

  const onSelect = selectInfo => {
    const calendarApi = selectInfo.view.calendar;
    if (activeStatus === null) {
      calendarApi.unselect();
      return;
    }

    const tempEvent = {
      start: selectInfo.start,
      end: convertDateToDateTime(selectInfo.end, true)
    };

    const adjustedEvents = allEvents.map(event => {
      return {
        ...event,
        end: convertDateToDateTime(event.end, true)
      };
    });
    const overlapRange = checkOverlapping(tempEvent, adjustedEvents);
    if (overlapRange !== null) return;

    const availability = createLocalEvent(activeStatus, {
      startDate: convertDateToDateTime(selectInfo.startStr),
      endDate: convertDateToDateTime(selectInfo.endStr, true)
    });

    dispatch(addLocalAvailability(availability));
  };

  const onEventResize = eventResizeInfo => {
    const { event } = eventResizeInfo;
    const { id, start, end } = event;

    dispatch(
      updateLocalAvailability(id, {
        startDate: convertDateToDateTime(start),
        endDate: convertDateToDateTime(end, true)
      })
    );
  };

  const onEventDrop = info => {
    const { event } = info;
    const { id, start, end } = event;

    dispatch(
      updateLocalAvailability(id, {
        startDate: convertDateToDateTime(start),
        endDate: convertDateToDateTime(end, true)
      })
    );
  };

  const onEventDidMount = info => {
    tippy(info.el, {
      allowHTML: true,
      content: titleBuilder(info.event.extendedProps, info.event.title),
      placement: 'bottom',
      interactive: true,
      interactiveBorder: 5,
      maxWidth: '10rem',
      offset: [0, 10],
      role: 'tooltip',
      theme: 'atellio',
      hideOnClick: true
      /* below is used to test tippy in the inspector */
      // trigger: 'click'
    });
  };

  const onSubmit = () => {
    if (
      local.length === 0 &&
      availabilities.length === 0 &&
      deleted.length === 0
    )
      return;

    const tokenFromCookie = cookies.get(RESOURCE_PORTAL);
    const { bearerToken, token } = decodeAndReadToken(tokenFromCookie);
    const { teamSchema, resourceId } = token;

    dispatch(createOrUpdateAvailabilities(teamSchema, resourceId, bearerToken));
  };

  const displayMessage =
    'Are you sure you want to leave without submitting your changes?';
  window.addEventListener('beforeunload', event => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue = displayMessage;
    }
  });

  const onHandleChange = (id, durationType, updatedDuration) => {
    if (updatedDuration === undefined) return;
    const durationDetails = {
      dateType: durationType,
      ...updatedDuration
    };
    dispatch(updateLocalAvailability(id, durationDetails));
  };

  const onDeleteAvailability = id => {
    dispatch(deleteLocalAvailability(id));
  };

  return (
    <section className={styles['CalendarRoute']}>
      {isDirty && <Prompt message={displayMessage} />}
      <div className="inset-L">
        <div className={styles['CalendarRoute__details']}>
          <div className={classnames(['text-16-700-black', 'stack-S'])}>
            {resourceFullName}, share your availability with{' '}
            {companyUserFirstName} at {companyName}
          </div>
          <div className="stack-L">
            <div className="text-16-600-eggplant-lighter">
              {companyUserFirstName} wants to know your availability between{' '}
              {startDate} - {endDate}.
            </div>
            <div className="text-16-600-eggplant-lighter">
              Share your availability by selecting the statuses below to fill
              the calendar
            </div>
          </div>
          <Grid>
            <GridCell vcenter={true}>
              <CalendarPens
                statuses={availabilityStatuses}
                setActivatedStatus={setActivatedStatus}
                activeStatus={activeStatus}
              />
            </GridCell>
            <GridCell width="auto" vcenter={true}>
              <PressStud
                label="Submit Calendar"
                appearance="primary"
                action={onSubmit}
                isLoading={
                  creating === AVAILABILITIES_ACTIONS.CREATE_AVAILABILITIES
                }
                isDisabled={!isDirty}
              />
            </GridCell>
          </Grid>
        </div>
      </div>
      <Grid>
        <GridCell>
          <div className="FullCalendar">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, momentTimezonePlugin]}
              selectable={true}
              editable={true}
              eventOverlap={false}
              fixedWeekCount={false}
              events={allEvents}
              timeZone="UTC"
              windowResize={({ view }) => {
                view.calendar.updateSize();
              }}
              windowResizeDelay={500}
              buttonText={{
                today: 'Today'
              }}
              select={onSelect}
              eventResize={onEventResize}
              eventDrop={onEventDrop}
              eventClick={({ event }) => {
                if (get(event, 'extendedProps.bookingId')) {
                  const booking = find(
                    bookings,
                    booking =>
                      booking.bookingId ===
                      get(event, 'extendedProps.bookingId')
                  );
                  if (booking && booking?.status.statusType !== 'HOLD') {
                    dispatch(openBookingSidebar(booking));
                  }
                }
              }}
              eventDidMount={onEventDidMount}
              eventContent={({ event }) => {
                const { publicId, extendedProps } = event._def;
                const deleteOnly =
                  event.title === 'Unavailable' ||
                  extendedProps?.status === 'HOLD';

                if (deleteOnly) {
                  return (
                    <CalendarDeletePopover
                      id={publicId}
                      title={event.title}
                      handleDelete={onDeleteAvailability}
                    />
                  );
                }

                const {
                  dateType,
                  estimatedDailyHours,
                  estimatedDailyMinutes,
                  estimatedStartTime,
                  estimatedEndTime
                } = extendedProps;

                const localEstimatedDailyHours =
                  estimatedDailyHours ||
                  DURATION_DEFAULTS.ESTIMATED_DAILY_HOURS;
                const localEstimatedDailyMinutes =
                  estimatedDailyMinutes ||
                  DURATION_DEFAULTS.ESTIMATED_DAILY_MINUTES;
                const localEstimatedStartTime =
                  estimatedStartTime || DURATION_DEFAULTS.ESTIMATED_START_TIME;
                const localEstimatedEndTime =
                  estimatedEndTime || DURATION_DEFAULTS.ESTIMATED_END_TIME;

                return (
                  <CalendarInputPopover
                    id={publicId}
                    title={event.title}
                    handleChange={onHandleChange}
                    handleDelete={onDeleteAvailability}
                    durationType={dateType}
                    estimatedDailyHours={localEstimatedDailyHours.toString()}
                    estimatedDailyMinutes={localEstimatedDailyMinutes.toString()}
                    estimatedStartTime={localEstimatedStartTime.toString()}
                    estimatedEndTime={localEstimatedEndTime.toString()}
                  />
                );
              }}
            />
          </div>
        </GridCell>
      </Grid>
    </section>
  );
};

export default CalendarRoute;
